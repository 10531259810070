// --- Importing the library
// tailoring the icon images that we use in Butter
import { library } from "@fortawesome/fontawesome-svg-core"

// Parcel seems to choke on VueJS for tree-shaking
// making these as individual calls to keep file size down
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown.js"
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons/faAngleDoubleRight.js"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight.js"
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp.js"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft.js"
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars.js"
import { faBook } from "@fortawesome/free-solid-svg-icons/faBook.js"
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt.js"
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons/faCalendarCheck.js"
import { faCalendarTimes } from "@fortawesome/free-solid-svg-icons/faCalendarTimes.js"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown.js"
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck.js"
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons/faCheckSquare.js"
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons/faCloudArrowUp.js"
import { faClone } from "@fortawesome/free-regular-svg-icons/faClone.js"
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog.js"
import { faCode } from "@fortawesome/free-solid-svg-icons/faCode.js"
import { faCompass } from "@fortawesome/free-regular-svg-icons/faCompass.js"
import { faCopy } from "@fortawesome/free-regular-svg-icons/faCopy.js"
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons/faExchangeAlt.js"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt.js"
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye.js"
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock.js"
import { faFile } from "@fortawesome/free-regular-svg-icons/faFile.js"
import { faFileAlt } from "@fortawesome/free-solid-svg-icons/faFileAlt.js"
import { faFileAudio } from "@fortawesome/free-solid-svg-icons/faFileAudio.js"
import { faFileDownload } from "@fortawesome/free-solid-svg-icons/faFileDownload.js"
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload.js"
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit.js"
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons/faEllipsisH.js"
import { faFileImage } from "@fortawesome/free-solid-svg-icons/faFileImage.js"
import { faFilePdf } from "@fortawesome/free-solid-svg-icons/faFilePdf.js"
import { faFileUpload } from "@fortawesome/free-solid-svg-icons/faFileUpload.js"
import { faFilter } from "@fortawesome/free-solid-svg-icons/faFilter.js"
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons/faGlobeAmericas.js"
import { faHistory } from "@fortawesome/free-solid-svg-icons/faHistory.js"
import { faImage } from "@fortawesome/free-regular-svg-icons/faImage.js"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle.js"
import { faLanguage } from "@fortawesome/free-solid-svg-icons/faLanguage.js"
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink.js"
import { faList } from "@fortawesome/free-solid-svg-icons/faList.js"
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock.js"
import { faPauseCircle } from "@fortawesome/free-regular-svg-icons/faPauseCircle.js"
import { faPen } from "@fortawesome/free-solid-svg-icons/faPen.js"
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt.js"
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons/faPlayCircle.js"
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus.js"
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons/faPlusSquare.js"
import { faRedo } from "@fortawesome/free-solid-svg-icons/faRedo.js"
import { faRss } from "@fortawesome/free-solid-svg-icons/faRss.js"
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch.js"
import { faTag } from "@fortawesome/free-solid-svg-icons/faTag.js"
import { faTags } from "@fortawesome/free-solid-svg-icons/faTags.js"
import { faTh } from "@fortawesome/free-solid-svg-icons/faTh.js"
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes.js"
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons/faTimesCircle.js"
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons/faTrashAlt.js"
import { faSync } from "@fortawesome/free-solid-svg-icons/faSync.js"
import { faUndo } from "@fortawesome/free-solid-svg-icons/faUndo.js"
import { faVideo } from "@fortawesome/free-solid-svg-icons/faVideo.js"

// associate each icon in the library for inclusion
library.add(
    faAngleDown,
    faAngleDoubleRight,
    faAngleRight,
    faAngleUp,
    faArrowLeft,
    faBars,
    faBook,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarTimes,
    faCaretDown,
    faCheck,
    faCheckSquare,
    faCog,
    faCode,
    faCompass,
    faCopy,
    faClone,
    faCloudArrowUp,
    faExchangeAlt,
    faExternalLinkAlt,
    faEye,
    faFile,
    faFileAlt,
    faFileAudio,
    faFileDownload,
    faDownload,
    faEdit,
    faEllipsisH,
    faFileImage,
    faFilePdf,
    faFileUpload,
    faFilter,
    faGlobeAmericas,
    faHistory,
    faImage,
    faInfoCircle,
    faLanguage,
    faLink,
    faList,
    faLock,
    faPauseCircle,
    faPen,
    faPencilAlt,
    faPlayCircle,
    faPlus,
    faPlusSquare,
    faRss,
    faRedo,
    faSearch,
    faTag,
    faTags,
    faTh,
    faTimes,
    faTimesCircle,
    faTrashAlt,
    faClock,
    faSync,
    faUndo,
    faVideo
)