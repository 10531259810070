// --- niceDate
// TODO: should be updated to generateNiceDate

import formatDate from "date-fns/format"
import parseISO from "date-fns/parseISO"

export default function niceDate ( date, format = "MMM dd, yyyy" ) {
    // thiws seems odd as date is required...
    // TODO: check to see /if/ its a date
    return date
        ? formatDate( parseISO( date ), format )
        : date
}