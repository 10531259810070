import difference from "lodash/difference"

export default function requiredProps ({ props, required, errorMessagePrefix = "" }) {
    const missingProps = difference( required, Object.keys( props ) )
    const message = errorMessagePrefix ? `${ errorMessagePrefix }: ` : ""

    if ( missingProps.length ) {
        throw `${ message }Set ${ missingProps.join( ", " ) }`
    }

    return props
}
