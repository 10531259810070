import AddActionIcon from "Icons/AddActionIcon.vue"
import AddComponentIcon from "Icons/AddComponentIcon.vue"
import ArrowDownTransitionIcon from "Icons/ArrowDownTransitionIcon.vue"
import ArrowOrderDown from "Icons/ArrowOrderDown.vue"
import ArrowOrderUp from "Icons/ArrowOrderUp.vue"
import ArrowsRepeatIcon from "Icons/ArrowsRepeatIcon.vue"
import ArrowUpArrowDownIcon from "Icons/ArrowUpArrowDownIcon.vue"
import BlogIcon from "Icons/BlogIcon.vue"
import BynderIcon from "Icons/BynderIcon.vue"
import CalendarCircleExclamationIcon from "Icons/CalendarCircleExclamationIcon"
import CalendarClockIcon from "Icons/CalendarClockIcon"
import CaretDownIcon from "Icons/CaretDownIcon.vue"
import CheckIcon from "Icons/CheckIcon.vue"
import CloudinaryIcon from "Icons/CloudinaryIcon.vue"
import CirclePlusIcon from "Icons/CirclePlusIcon.vue"
import CodeIcon from "Icons/CodeIcon.vue"
import CodeCommitIcon from "Icons/CodeCommitIcon.vue"
import CodeCompareIcon from "Icons/CodeCompareIcon.vue"
import CogIcon from "Icons/CogIcon.vue"
import CollapseIcon from "Icons/Collapse.vue"
import CollapseLeftIcon from "Icons/CollapseLeft.vue"
import ComponentPickerIcon from "Icons/ComponentPickerIcon.vue"
import ComponentsIcon from "Icons/ComponentsIcon.vue"
import ContentModelIcon from "Icons/ContentModelIcon.vue"
import CreditCardIcon from "Icons/CreditCardIcon.vue"
import DatabaseIcon from "Icons/DatabaseIcon.vue"
import DeeplFullIcon from "Icons/DeeplFullIcon.vue"
import DeeplIcon from "Icons/DeeplIcon.vue"
import DocsIcon from "Icons/DocsIcon.vue"
import ExpandIcon from "Icons/Expand.vue"
import ExpandRightIcon from "Icons/ExpandRight.vue"
import FileLinesIcon from "Icons/FileLinesIcon.vue"
import FolderIcon from "Icons/FolderIcon.vue"
import FoldersIcon from "Icons/FoldersIcon.vue"
import FormstackIcon from "Icons/FormstackIcon.vue"
import FormstackLogo from "Icons/FormstackLogo.vue"
import HandleIcon from "Icons/HandleIcon.vue"
import HomeIcon from "Icons/HomeIcon.vue"
import JotformFullIcon from "Icons/JotformFullIcon.vue"
import JotformIcon from "Icons/JotformIcon.vue"
import KnowledgeBaseIcon from "Icons/KnowledgeBaseIcon.vue"
import LaptopIcon from "Icons/LaptopIcon.vue"
import LinkIcon from "Icons/LinkIcon.vue"
import LoadingIcon from "Icons/LoadingIcon.vue"
import LokaliseFullIcon from "Icons/LokaliseFullIcon.vue"
import LokaliseIcon from "Icons/LokaliseIcon.vue"
import MediaIcon from "Icons/MediaIcon.vue"
import PageIcon from "Icons/PageIcon.vue"
import PencilEditorIcon from "Icons/PencilEditorIcon.vue"
import QuoteLeftIcon from "Icons/QuoteLeftIcon.vue"
import QuoteRightIcon from "Icons/QuoteRightIcon.vue"
import RadiosIcon from "Icons/RadiosIcon.vue"
import ReceiptIcon from "Icons/ReceiptIcon.vue"
import ReferenceIcon from "Icons/ReferenceIcon.vue"
import ReviewIcon from "Icons/ReviewIcon.vue"
import RocketIcon from "Icons/RocketIcon.vue"
import SignoutIcon from "Icons/SignoutIcon.vue"
import SitemapIcon from "Icons/SitemapIcon.vue"
import SlackIcon from "Icons/SlackIcon.vue"
import SquareCheckIcon from "Icons/SquareCheckIcon.vue"
import SquareXmarkIcon from "Icons/SquareXmarkIcon.vue"
import TableIcon from "Icons/TableIcon.vue"
import ThLargeIcon from "Icons/ThLargeIcon.vue"
import TinyIcon from "Icons/TinyIcon.vue"
import TypeformIcon from "Icons/TypeformIcon.vue"
import TypeformLogo from "Icons/TypeformLogo.vue"
import UserLockIcon from "Icons/UserLockIcon.vue"
import UsersIcon from "Icons/UsersIcon.vue"

export default function ( Vue ) {
    Vue.component( "AddActionIcon", AddActionIcon )
    Vue.component( "AddComponentIcon", AddComponentIcon )
    Vue.component( "ArrowOrderDown", ArrowOrderDown )
    Vue.component( "ArrowOrderUp", ArrowOrderUp )
    Vue.component( "ArrowDownTransitionIcon", ArrowDownTransitionIcon )
    Vue.component( "ArrowsRepeatIcon", ArrowsRepeatIcon )
    Vue.component( "ArrowUpArrowDownIcon", ArrowUpArrowDownIcon )
    Vue.component( "BlogIcon", BlogIcon )
    Vue.component( "BynderIcon", BynderIcon )
    Vue.component( "CalendarCircleExclamationIcon", CalendarCircleExclamationIcon )
    Vue.component( "CalendarClockIcon", CalendarClockIcon )
    Vue.component( "CaretDownIcon", CaretDownIcon )
    Vue.component( "CheckIcon", CheckIcon )
    Vue.component( "CloudinaryIcon", CloudinaryIcon )
    Vue.component( "CirclePlusIcon", CirclePlusIcon )
    Vue.component( "CodeIcon", CodeIcon )
    Vue.component( "CodeCommitIcon", CodeCommitIcon )
    Vue.component( "CodeCompareIcon", CodeCompareIcon )
    Vue.component( "CogIcon", CogIcon )
    Vue.component( "CollapseIcon", CollapseIcon )
    Vue.component( "CollapseLeftIcon", CollapseLeftIcon )
    Vue.component( "ComponentPickerIcon", ComponentPickerIcon )
    Vue.component( "ComponentsIcon", ComponentsIcon )
    Vue.component( "ContentModelIcon", ContentModelIcon )
    Vue.component( "CreditCardIcon", CreditCardIcon )
    Vue.component( "DatabaseIcon", DatabaseIcon )
    Vue.component( "DeeplFullIcon", DeeplFullIcon )
    Vue.component( "DeeplIcon", DeeplIcon )
    Vue.component( "DocsIcon", DocsIcon )
    Vue.component( "ExpandIcon", ExpandIcon )
    Vue.component( "ExpandRightIcon", ExpandRightIcon )
    Vue.component( "FileLinesIcon", FileLinesIcon )
    Vue.component( "FolderIcon", FolderIcon )
    Vue.component( "FoldersIcon", FoldersIcon )
    Vue.component( "FormstackIcon", FormstackIcon )
    Vue.component( "FormstackLogo", FormstackLogo )
    Vue.component( "HandleIcon", HandleIcon )
    Vue.component( "HomeIcon", HomeIcon )
    Vue.component( "JotformFullIcon", JotformFullIcon )
    Vue.component( "JotformIcon", JotformIcon )
    Vue.component( "KnowledgeBaseIcon", KnowledgeBaseIcon )
    Vue.component( "LaptopIcon", LaptopIcon )
    Vue.component( "LinkIcon", LinkIcon )
    Vue.component( "LoadingIcon", LoadingIcon )
    Vue.component( "LokaliseFullIcon", LokaliseFullIcon )
    Vue.component( "LokaliseIcon", LokaliseIcon )
    Vue.component( "MediaIcon", MediaIcon )
    Vue.component( "PageIcon", PageIcon )
    Vue.component( "PencilEditorIcon", PencilEditorIcon )
    Vue.component( "QuoteLeftIcon", QuoteLeftIcon )
    Vue.component( "QuoteRightIcon", QuoteRightIcon )
    Vue.component( "RadiosIcon", RadiosIcon )
    Vue.component( "ReceiptIcon", ReceiptIcon )
    Vue.component( "ReferenceIcon", ReferenceIcon )
    Vue.component( "ReviewIcon", ReviewIcon )
    Vue.component( "RocketIcon", RocketIcon )
    Vue.component( "SignoutIcon", SignoutIcon )
    Vue.component( "SitemapIcon", SitemapIcon )
    Vue.component( "SlackIcon", SlackIcon )
    Vue.component( "SquareCheckIcon", SquareCheckIcon )
    Vue.component( "SquareXmarkIcon", SquareXmarkIcon )
    Vue.component( "TableIcon", TableIcon )
    Vue.component( "ThLargeIcon", ThLargeIcon )
    Vue.component( "TinyIcon", TinyIcon )
    Vue.component( "TypeformIcon", TypeformIcon )
    Vue.component( "TypeformLogo", TypeformLogo )
    Vue.component( "UserLockIcon", UserLockIcon )
    Vue.component( "UsersIcon", UsersIcon )
}
