// --- useClickOutsideDirective
// note: due to the way Vue directives are structured,
// it does not seem that we can use useManageEventListener here

export default function useClickOutsideDirective () {
    return {
        bind ( el, binding, vnode ) {
            el.clickOutsideEvent = function ( event ) {
                // The path property of Event objects is non-standard.
                // The standard equivalent is composedPath
                const path = event.path
                    || ( event.composedPath && event.composedPath() )

                if ( !( el === event.target || path.indexOf( el ) > -1 ) ) {
                    vnode.context[binding.expression]( event )
                }
            }

            document.body.addEventListener( "click", el.clickOutsideEvent )
        },

        unbind ( el ) {
            document.body.removeEventListener( "click", el.clickOutsideEvent )
        },
    }
}