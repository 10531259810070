// useLinkWithDisabledState
// directive to allow permanently disable an anchor on condition
// If value set to true anchor element is not touched
// else `href` is set to empty string, pointer events are set to none and
// `disabled` attribute is set to the element for styling

export default function useLinkWithDisabledState () {
    return {
        bind ( el, binding, vnode ) {
            const enabled = binding.value

            if ( !enabled ) {
                el.removeAttribute( "href" )
                el.style.pointerEvents = "none"
                el.setAttribute( "disabled", true )
            }
        }
    }
}
