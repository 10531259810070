import "/ui-unify/css/app.css"

import VueCompositionAPI, * as compApi from "@vue/composition-api"
Vue.use( VueCompositionAPI )
// apply composition-api as a global app
// so we only install the composition on global Vue once
// but still get debug output through vue-devtools
window.VueCompositionAPI = compApi

// increase performance for production
Vue.config.devtools = process.env.NODE_ENV !== "production"
Vue.config.productionTip = false
Vue.config.performance = process.env.NODE_ENV !== "production"

// @note    we use these component a lot,
// @        we universally register to forgo local registrations
import { niceDate } from "utils/dates"
Vue.filter( "niceDate", niceDate )

import FaIcon from "FontAwesomeIcon"
Vue.component( "FaIcon", FaIcon )

import attachIcons from "Icons/icons.js"
attachIcons( Vue )

import ButterIcon from "Media/ButterIcon.vue"
Vue.component( "ButterIcon", ButterIcon )

import useClickOutsideDirective from "services/useClickOutsideDirective"
Vue.directive( "click-outside", useClickOutsideDirective() )

import {
    useButtonWithDisabledState,
    useLinkWithDisabledState
} from "services/useElementsWithDisabledState"
Vue.directive( "button-with-disabled-state", useButtonWithDisabledState() )
Vue.directive( "link-with-disabled-state", useLinkWithDisabledState() )
