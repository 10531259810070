// useButtonWithDisabledState
// directive to allow permanently disable a button on condition
// enabled - (boolean) If true button has click event
//           that triggers callback function. If false button is disabled
//           and no action is triggered on click
// callbak - (function) callback for click event
// e.g.
/* <button
    type="button"
    v-button-with-disabled-state="{
        enabled: isEnabled,
        callback: clickHandler
    }"
>
    Update
</button> */

import requiredProps from "utils/requiredProps"


export default function useButtonWithDisabledState () {
    function activate ( el, binding ) {
        const {
            enabled,
            callback
        } = requiredProps({
            errorMessagePrefix: "useButtonWithDisabledState",
            props: binding.value || {},
            required: [ "callback" ]
        })

        if ( enabled ) {
            el.callback = function ( event ) {
                return callback( event )
            }
            el.addEventListener( "click", el.callback )
        }
        else {
            el.disabled = true
            el.style.pointerEvents = "none"
        }
    }

    function deactivate ( el ) {
        if ( el.callback ) {
            el.removeEventListener( "click", el.callback )
        }
    }

    return {
        bind ( el, binding, vnode ) {
            activate( el, binding )
        },
        unbind ( el ) {
            deactivate( el )
        },
        update ( el, binding, vnode ) {
            // when element gets updated it might have changes
            // related to `disabled` attribute
            // so we want to override all attributes related to desabled state
            deactivate( el )
            activate( el, binding )
        },
    }
}
